import  React, { Fragment } from "react"
import Select from 'react-select'




const Dropdown = () => {
    var array1 = ['a', 'b', 'c'];
    var technologyList = [];
    array1.forEach(function(element) {
        technologyList.push({ label:element, value: element })
    });
    return (
        

<Select options={ technologyList } />

    )
  }
  
  export default Dropdown